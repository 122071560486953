import React from 'react';
import clsx from 'clsx';

interface PushableButtonProps {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  theme?: 'default' | 'professional';
}

const PushableButton: React.FC<PushableButtonProps> = ({ 
  children, 
  onClick, 
  disabled, 
  type = 'button', 
  className 
}) => {
  return (
    <button 
      className={clsx('pushable select-none', disabled && 'disabled', className)}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <span className="pushable-shadow"></span>
      <span className="pushable-edge"></span>
      <span className="pushable-front">{children}</span>
    </button>
  );
};

const defaultStyles = `
.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}
.pushable.disabled {
  cursor: not-allowed;
  filter: grayscale(100%) brightness(90%);
}
.pushable-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.pushable-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}
.pushable-front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.pushable:hover:not(.disabled) {
  filter: brightness(110%);
}
.pushable:hover:not(.disabled) .pushable-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.pushable:active:not(.disabled) .pushable-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.pushable:hover:not(.disabled) .pushable-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.pushable:active:not(.disabled) .pushable-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.pushable:focus:not(:focus-visible) {
  outline: none;
}
`;

const professionalStyles = `
.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}
.pushable.disabled {
  cursor: not-allowed;
  filter: grayscale(100%) brightness(90%);
}
.pushable-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.15);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.pushable-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(0deg 0% 40%) 0%,
    hsl(0deg 0% 60%) 8%,
    hsl(0deg 0% 60%) 92%,
    hsl(0deg 0% 40%) 100%
  );
}
.pushable-front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: hsl(0deg 0% 20%);
  background: hsl(0deg 0% 95%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.pushable:hover:not(.disabled) {
  filter: brightness(105%);
}
.pushable:hover:not(.disabled) .pushable-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.pushable:active:not(.disabled) .pushable-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.pushable:hover:not(.disabled) .pushable-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.pushable:active:not(.disabled) .pushable-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.pushable:focus:not(:focus-visible) {
  outline: none;
}
`;

const PushableButtonWithStyles: React.FC<PushableButtonProps> = ({ 
  children, 
  onClick, 
  disabled, 
  type,
  className,
  theme = 'default'
}) => {
  const styles = theme === 'professional' ? professionalStyles : defaultStyles;

  return (
    <>
      <style>{styles}</style>
      <PushableButton 
        onClick={onClick} 
        disabled={disabled} 
        type={type} 
        className={className}
      >
        {children}
      </PushableButton>
    </>
  );
};

export default PushableButtonWithStyles;